import { useModal } from 'vue-final-modal';
import { defineAsyncComponent } from '#imports';

export const useProductReview = () => {
  const openProductReviewPopup = (id = '') => {
    const { open, close } = useModal({
      modalId: 'productReview',
      component: defineAsyncComponent(
        () => import('@/components/popups/ProductReview.vue'),
      ),
      attrs: {
        targetProductId: id,
        closeModal: () => {
          close();
        },
      },
    });

    open();
  };

  return {
    openProductReviewPopup,
  };
};
