<script setup>
import { defineAsyncComponent } from '#imports';
import { useModal } from 'vue-final-modal';
import { useProductReview } from '@/composables/useProductReview';
import { usePolicePopup } from '@/composables/usePolicePopup';
import { police } from '@/configs/police';

import UiLinkTag from '@/components/ui/UiLinkTag.vue';
import UiButton from '@/components/ui/button/UiButton.vue';
import { commonAnalytic } from '@/analytic/common';

const { openProductReviewPopup } = useProductReview();
const { openPolicePopup } = usePolicePopup();

const props = defineProps({
  productId: {
    type: String,
    default: undefined,
  },
});

function openPolicePopupHandler(analyticLabel, police) {
  sendAnalytic(analyticLabel);
  openPolicePopup(police);
}
function openFeedbackPopupHandler() {
  sendAnalytic('помощь');
  openFeedbackPopup();
}
function openFeedbackPopup() {
  const { open, close } = useModal({
    modalId: 'feedbackIsAuth',
    component: defineAsyncComponent(
      () => import('@/components/popups/FeedbackIsAuth.vue'),
    ),
    attrs: {
      closeModal: () => {
        close();
      },
    },
  });

  open();
}
function openProductReview() {
  sendAnalytic('оставить отзыв о продукте');

  openProductReviewPopup(props.productId);
}
function sendAnalytic(label) {
  commonAnalytic.footerInteraction(label);
}
</script>

<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="footer__body">
        <div class="footer__body-top">
          <div class="footer__nav-columns">
            <div class="footer__nav-column">
              <ul class="footer__nav-links-list">
                <li class="footer__nav-links-item">
                  <UiLinkTag
                    to="/"
                    class="footer__nav-link"
                    @click="sendAnalytic('главная')"
                  >
                    <span class="footer__font footer__font--nav-link">
                      главная
                    </span>
                  </UiLinkTag>
                </li>

                <li class="footer__nav-links-item">
                  <UiLinkTag
                    to="/products"
                    class="footer__nav-link"
                    @click="sendAnalytic('продукция')"
                  >
                    <span class="footer__font footer__font--nav-link">
                      продукция
                    </span>
                  </UiLinkTag>
                </li>

                <li class="footer__nav-links-item">
                  <UiLinkTag
                    to="/profile"
                    class="footer__nav-link"
                    @click="sendAnalytic('личный кабинет')"
                  >
                    <span class="footer__font footer__font--nav-link">
                      личный кабинет
                    </span>
                  </UiLinkTag>
                </li>
              </ul>
            </div>

            <div class="footer__nav-column">
              <ul class="footer__nav-links-list">
                <li class="footer__nav-links-item">
                  <UiLinkTag
                    class="footer__nav-link"
                    @click="openFeedbackPopupHandler"
                  >
                    <span class="footer__font footer__font--nav-link">
                      помощь
                    </span>
                  </UiLinkTag>
                </li>
              </ul>
            </div>
          </div>

          <UiButton
            theme="white-footer"
            class="footer__button footer__button--feedback"
            text="оставить отзыв о продукте"
            @click="openProductReview"
          />
        </div>

        <div class="footer__body-bottom">
          <ul class="footer__copyrights-list">
            <li class="footer__copyrights-item">
              <button
                type="button"
                class="footer__copyright-link"
                @click="
                  openPolicePopupHandler(
                    'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ',
                    police.termsConditions,
                  )
                "
              >
                <span class="footer__font footer__font--copyright-link">
                  ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
                </span>
              </button>
            </li>

            <li class="footer__copyrights-item">
              <button
                type="button"
                class="footer__copyright-link"
                @click="
                  openPolicePopupHandler(
                    'ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ',
                    police.privacyPolicy,
                  )
                "
              >
                <span class="footer__font footer__font--copyright-link">
                  ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ
                </span>
              </button>
            </li>
          </ul>

          <div class="footer__rights">
            <span class="footer__font footer__font--rights">
              © Winston, 2024
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer {
  background: $back-gradient;

  &__container {
    max-width: em(1920);
    margin: 0 auto;
    width: 100%;
    padding: 0 em(120);

    @include media-breakpoint-down(lg) {
      padding: 0 em(87);
    }

    @include media-breakpoint-down(sm) {
      padding: 0 em(32);
    }
  }

  &__body {
  }

  &__body-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid $color-white;
    padding-top: em(70);
    padding-bottom: em(59);

    @include media-breakpoint-down(lg) {
      padding-top: em(46);
      padding-bottom: em(45);
    }

    @include media-breakpoint-down(sm) {
      padding-top: em(38);
      padding-bottom: em(55);
      flex-direction: column;
    }
  }

  &__body-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: em(33);
    padding-bottom: em(48);

    @include media-breakpoint-down(lg) {
      padding-top: em(24);
      padding-bottom: em(32);
      align-items: center;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
      padding-top: em(55);
      padding-bottom: em(31);
    }
  }

  &__nav-columns {
    display: flex;
    padding-top: em(12);

    @include media-breakpoint-down(lg) {
      padding-top: em(10);
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: em(55);
    }
  }

  &__nav-column {
    min-width: em(290);

    @include media-breakpoint-down(lg) {
      min-width: em(135);
    }

    &:not(:last-child) {
      margin-right: em(24);

      @include media-breakpoint-down(lg) {
        margin-right: em(20);
      }

      @include media-breakpoint-down(sm) {
        margin-right: em(27);
      }
    }
  }

  &__nav-links-list {
  }

  &__nav-links-item {
    &:not(:last-child) {
      margin-bottom: em(31);

      @include media-breakpoint-down(lg) {
        margin-bottom: em(20);
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: em(15);
      }
    }
  }

  &__nav-link {
    color: $color-white;
    opacity: 0.8;

    transition: $ease-out $time-normal;
    transition-property: opacity;

    @include hover() {
      opacity: 1;
    }
  }

  &__copyrights-list {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: em(53);
    }
  }

  &__copyrights-item {
    &:not(:last-child) {
      margin-right: em(100);

      @include media-breakpoint-down(lg) {
        margin-right: em(75);
      }

      @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-bottom: em(14);
      }
    }
  }

  &__button {
    &--feedback {
    }
  }

  &__copyright-link {
    position: relative;

    color: $color-white;
    opacity: 0.6;

    transition: $ease-out $time-normal;
    transition-property: opacity;

    &:after {
      content: '';
      position: absolute;
      bottom: em(-3);
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-white;
      opacity: 0.5;

      @include media-breakpoint-down(lg) {
        bottom: em(-1);
      }
    }

    @include hover() {
      opacity: 1;
    }
  }

  &__rights {
    color: $color-white;
    opacity: 0.6;
  }

  &__font {
    &--nav-link {
      @include button;

      @include media-breakpoint-down(lg) {
        font-size: em(12);
        letter-spacing: 0.03em;
      }

      @include media-breakpoint-down(sm) {
        font-size: em(15);
        letter-spacing: 0;
      }
    }

    &--copyright-link {
      display: block;
      @include text;

      @include media-breakpoint-down(lg) {
        font-size: em(9);
        letter-spacing: 0.03em;
        line-height: 1.8;
      }

      @include media-breakpoint-down(sm) {
        font-size: em(12);
        letter-spacing: 0.05em;
        line-height: 1.8;
      }
    }

    &--rights {
      @include text;
      text-transform: none;

      @include media-breakpoint-down(lg) {
        font-size: em(9);
      }

      @include media-breakpoint-down(lg) {
        font-size: em(12);
      }
    }
  }
}
</style>
